import React, { useState, useEffect } from 'react';
import { postData, getCreds } from "../../api";
import { VideoPlayer } from "../VideoPlayer/index";

const Player = props => {
  const [hlsUrl, setHlsUrl] = useState("");
  const [inited, setInited] = useState(false);
  let repeatGetData = null;

  useEffect(() => {
    async function startVideo() {
      try {
        await postData({ type: "start", value: "video" });
        setInited(true);
      } catch (error) {
        console.log("Video start command has not been sent");
      }
    }
    startVideo();
  }, []);

  useEffect(() => {
    if (inited) {
      console.log('Fetching HLS Streaming Session URL');
      async function getHLSUrl() {
        const { HLSStreamingSessionURL, error } = await getCreds();
        if (error) {
          repeatGetData = setTimeout(() => getHLSUrl(), 10000);
          return;
        }
        if (repeatGetData) {
          clearTimeout(repeatGetData);
        }
        console.log(HLSStreamingSessionURL, "hlsURL");
        setHlsUrl(HLSStreamingSessionURL);
      }
      try {
        getHLSUrl();
      } catch (error) {
        repeatGetData = setInterval(() => getHLSUrl(), 10000);
      }
    }
  }, [inited])

  return (
    <VideoPlayer title={props.title} hlsUrl={hlsUrl} />
  )
}

export { Player };
