import React from 'react';
import './Zoom.css';
import { ReactComponent as ZoomIn } from "../../assests/icons/zoom-in.svg";
import { ReactComponent as ZoomOut } from "../../assests/icons/zoom-out.svg";

const Zoom = (props) => {
  const { onZoom = () => { } } = props;
  return (
    <div className="zoomContainer">
      <button className="zoomBtn" onClick={() => onZoom("in")}><ZoomIn style={{ padding: 11 }} /></button>
      <button className="zoomBtn" onClick={() => onZoom("out")}><ZoomOut style={{ padding: 11, height: 10 }} /></button>
    </div>
  );
}

export { Zoom };
