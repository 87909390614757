import React from 'react';
import { Button } from "../Button/index";
import './SwitchBlock.css';

const SwitchBlock = (props) => {
  const { onBtnClick = () => {} } = props;
  return (
    <div className="switchBlock">
      <Button title="Issue vending" style={{ marginRight: 8 }} onClick={() => onBtnClick("vending")}/>
      <Button title="Issue locker" onClick={() => onBtnClick("locker")}/>
    </div>
  );
}

export { SwitchBlock };
