import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import "./VideoPlayer.css";

import { SwitchBlock } from "../SwitchBlock/index";
import { Zoom } from "../Zoom/index";
import { Navigation } from "../Navigation/index";
import { postData } from "../../api";

let keepAliveTimer = null;

const sendCommand = async command => {
  try {
    await postData(command);
  } catch (error) {
    console.log("Command hasn't been sent");
  }
}

const keepFocused = () => {
  const interval = setInterval(() => {
    sendCommand({ type: "keep", value: "active" })
  }, 20000);
  return interval;
}

const resetInterval = () => {
  clearInterval(keepAliveTimer);
  keepAliveTimer = keepFocused();
}

const VideoPlayer = props => {
  const { hlsUrl, title = "" } = props;
  const [ready, setReady] = useState(false);

  const containerRef = useRef(null);
  const fullScreen = window.innerWidth > 860;
  const size = {
    width: `${fullScreen ? 860 : window.innerWidth}px`,
    height: fullScreen ? "460px" : "auto"
  }

  const [playerSize, setPlayerSize] = useState(size);

  const newSize = containerRef.current?.getBoundingClientRect();
  const newHeight = newSize?.height;


  useEffect(() => {
    if (hlsUrl) {
      keepAliveTimer = keepFocused();
    }
  }, [hlsUrl]);


  useEffect(() => {
    setPlayerSize({});
  }, [ready]);

  useEffect(() => {
    const handleResize = () => {
      const fullScreen = window.innerWidth > 860;
      const size = {
        width: `${fullScreen ? 860 : window.innerWidth}px`,
        height: fullScreen ? "460px" : "auto"
      }
      setPlayerSize(size);
    }

    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // returned function will be called on component unmount 
    return () => {
      sendCommand({ type: "stop", value: "video" });
      clearInterval(keepAliveTimer);
    }
  }, [])

  return (
    <div className="playerContainer">
      <h4 className="videoTitle">{title}</h4>
      <div className="mobileSwitch">
        <SwitchBlock onBtnClick={type => sendCommand({ type })} />
      </div>
      <div className="playerWrapper" ref={containerRef} style={!hlsUrl ? { backgroundColor: " #181818", minHeight: 360 } : {}}>
        <ReactPlayer url={hlsUrl} {...size} playing={true} controls={true} onReady={() => setReady(true)} />
        <div className="actionsWrapper"
          style={{
            height: newHeight ? newHeight - 60 : 0,
            marginTop: `-${newHeight || 0}px`,
            display: !ready ? "none" : "flex"
          }}>
          <div className="tabletSwitch">
            <SwitchBlock onBtnClick={type =>  sendCommand({ type }) } />
          </div>
          <div className="btnsWrapper">
            <Zoom onZoom={value => { sendCommand({ type: "zoom", value }); resetInterval(); }} />
            <Navigation move={value => { sendCommand({ type: "move", value }); resetInterval(); }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { VideoPlayer };
