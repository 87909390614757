import React from 'react';
import { ReactComponent as LogoIcon } from "../../assests/icons/srx-logo.svg";
import './Logo.css';

const Logo = () => {
  return (
    <div className="logo">
      <a className="logoLink" href="https://storeroomlogix.com/">
        <LogoIcon />
      </a>
    </div>
  );
}

export { Logo };
