import React from 'react';
import './Main.css';
import { Player } from "../../components/Player/index";

const Main = () => {
  return (
    <div className="main">
      <div className="title">Device Demo Page</div>
      <Player title="Live Feed"/>
    </div>
  );
}

export { Main };
