import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="leftWrapper">
          <span className="regularText">Storeroom Logix | Next Generation of VMI Solutions</span>
          <span className="copyRight">Copyright @ 2020. Storeroom Logix All Rights Reserved.</span>
        </div>
        <div className="rightWrapper">
          <div className="linkWrapper">
            <a className="regularText" href="https://storeroomlogix.com/">Term of Use</a>
            <a className="regularText" href="https://storeroomlogix.com/privacy-policy/">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Footer };
