const url = process.env.REACT_APP_API_URL;
const actionUrl = `${url}action`;
const authUrl = `${url}auth`;

async function postData(data = {}) {
  // Default options are marked with *
  try {
    const response = await fetch(actionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'false'
      },
      body: JSON.stringify(data)
    });

    return response.json(); // parses JSON response into native JavaScript objects
  }
  catch (error) {
    console.log(error);
  }
}

async function getCreds() {
  // Default options are marked with *
  try {
    const response = await fetch(authUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'false'
      }
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
  catch (error) {
    console.log(error);
  }
}

export { postData, getCreds };