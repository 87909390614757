import React from 'react';
import './Navigation.css';
import { ReactComponent as ArrowIcon } from "../../assests/icons/arrow.svg";

const Navigation = (props) => {
  const { move } = props;
  return (
    <div className="navigationContainer">
      <div className="navigationWrapper">
        <div className="topArrow">
          <button className="arrowBtn" onClick={() => move("up")}><ArrowIcon /></button>
        </div>
        <div className="leftRightArrow">
          <button className="arrowBtn" onClick={() => move("left")}><ArrowIcon style={{ transform: "rotate(-90deg)" }} /></button>
          <button className="arrowBtn" onClick={() => move("right")}><ArrowIcon style={{ transform: "rotate(90deg)" }} /></button>
        </div>
        <div className="bottomArrow">
          <button className="arrowBtn" onClick={() => move("down")}><ArrowIcon style={{ transform: "rotate(180deg)" }} /></button>
        </div>
      </div>
    </div>
  );
}

export { Navigation };
