import React from 'react';
import './App.css';
import { Main } from "./containers/Main/index";
import { Logo } from "./components/Logo/index";
import { Footer } from "./components/Footer/index";

function App() {
  return (
    <>
      <div className="body">
        <Logo />
        <Main />
      </div>
      <Footer />
    </>
  );
}

export default App;
