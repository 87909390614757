import React from 'react';
import './Button.css';

const Button = (props) => {
  const { onClick = () => { } } = props;
  return (
    <button className="actionButton" style={props.style || {}} onClick={onClick}>
      {props.title}
    </button>
  );
}

export { Button };
